<template>
	<div class=""><h1>企业认证</h1></div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {},
	created() {},
	computed: {}
};
</script>

<style lang="less" scoped></style>
